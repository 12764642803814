import React from "react"
import Slider from "react-slick"
import {
  icon,
  flex,
  square,
  white,
  border,
  largest,
} from '../components/layout.module.css'

const settings = {
  dots: true,
  dotsClass: `slick-dots ${square}`,
  arrows: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
    responsive: [
    //     {
    //       breakpoint: 768,
    //       settings: {
    //         slidesToShow: 2,
    //         slidesToScroll: 2,
    //         initialSlide: 2
    //       }
    //     },
        {
          breakpoint: 580,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            rows: 3,
          }
        }
      ]
}

const ApproachCarousel = () => {
  return (
    <Slider {...settings}>

      <div><div className={flex}>
        <h2 className={largest+' '+icon} style={{textAlign:"right"}}>1</h2>
        <div>
          <h4>Thoughtful Expansion</h4>
          <p>Our partner organizations can expand their own capacity to handle the influx of referrals and provide services in a meaningful way. Members know and trust these community organizations who are serving their neighborhoods through a constellation of services. Rather than hearing “C3 will help you with housing,” a member is connected to a partner who has proven their commitment to their community time and time again.</p>
        </div>
        <blockquote className={white+' '+border}><p>We recognize our members are sharing sensitive and intimate details when they open up to us about their social needs. Creating a safe environment for our members to share their life circumstances is important to us.<br/><strong>– Annie Pham, Manager of Flexible Services, Community Care Cooperative</strong></p></blockquote>
      </div></div>

      <div><div className={flex}>
        <h2 className={largest+' '+icon} style={{textAlign:"right"}}>2</h2>
        <div>
          <h4>You're the Expert</h4>
          <p>Each partner can focus on their area of expertise. The SSO case managers are experts in housing or food security, while staff of the C3 clinical programs and health centers can concentrate their efforts on physical and behavioral health needs.</p>
        </div>
        <blockquote className={white+' '+border}><p>There was a space of ‘you’re the housing experts, so we will let you be the housing experts.’ They don’t tell us how to do our work, which is important. Instead, they do a great job of asking ‘what do you need to be successful?’<br/><strong>– Carla Beaudoin, Chief Strategic Partnership Officer, Metro Housing|Boston</strong></p></blockquote>
      </div></div>

      <div><div className={flex}>
        <h2 className={largest+' '+icon} style={{textAlign:"right"}}>3</h2>
        <div>
          <h4>Close Partnerships</h4>
          <p>Our FSP was built and co-designed in close partnership with the staff of our social service organization partners. This means the nuts and bolts of the program are infused with the expertise and experience of the leaders at those organizations. Our expertise ensures the program is compliant with MassHealth program and funding requirements and health-related goals for our members, while the SSOs’ expertise ensures the program lifts up proven interventions and is grounded in community voice.</p>
        </div>
        <blockquote className={white+' '+border}><p>Having someone like C3 has been so immensely helpful in making sure our program is as standard as possible, as by the book as possible, but also understanding that this is a program that is still growing into itself and still has flexibility within it to meet the varying member needs.<br/><strong>- Delilah Kiely, Flexible Services Program Director, Eliot Community Human Services</strong></p></blockquote>
      </div></div>

    </Slider>
  )
}

export default ApproachCarousel