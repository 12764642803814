import * as React from 'react'
import Layout from '../components/layout'
import { StaticImage } from 'gatsby-plugin-image'
import ApproachCarousel from '../components/approachCarousel'
import {
  content,  
  cover,
  container,
  flex,
  thin,
  center,
  grey,
  border,
  white,
  larger,
} from '../components/layout.module.css'

const Approach = () => {
  return (
    <Layout pageTitle="Our Approach">

      <div className={cover}>
        <StaticImage src="../images/banner-approach.jpg" alt="" style={{
          position: "absolute",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          filter: "brightness(0.75)",
        }}
        loading="eager"
        layout="fullWidth"></StaticImage>
        <div className={container +' '+ content}>
          <h1>Our Approach</h1>
        </div>
      </div>

      <div className={container}>
        <h2 className={thin}>C3’s Approach to the Flexible Services Program (FSP)</h2><br/>
        
        <div className={thin}><blockquote className={white+' '+border}>
          <p>If we are really good at primary care and caring for our members then we understand the amazing opportunity that the Flexible Services Program gives us to address the root causes of poor health.  This program offers the dollars to do some of the things that nurses, social workers, and community health workers – those who really understand the underlying drivers of poor health - have been saying for decades are necessary to improve members’ situations.<br/><strong> - Kim Prendergast, Senior Director of Social Health, Community Care Cooperative </strong></p>
        </blockquote></div>
      </div>
      
      <div className={container}>
        <div className={flex}>
          <div>
            <p>At C3, we believe in the power of holistic, community-based care. Great health is our primary purpose, and we know that members’ social circumstances stem from inequitable opportunities that disproportionately harm communities of color and under-resourced communities.</p>
            <p>The Flexible Services Program gave us a channel – and funding – to advance health equity. Managing diabetes if someone is unhoused or unable to access healthy food means something completely different for a members’ care than if that member has a stable and safe place to live, money and access to healthy food, and time to cook.</p>
            <p>The FSP is aligned with our core values of Social Justice, Respect, and Results, and we approach this work from a place of compassion. This is reflected at all levels – from our leadership to the community health workers working with members every day at our partner health centers and with our partner social service organizations (SSOs) dedicated to this work.</p>
          </div>
          <div>
            <blockquote className={white+' '+border}>
              <p>C3’s vision aligns with what we want to do - creating truly integrated health care. They are responsive, flexible, and collaborative. C3 also understands the needs of our population and the challenges that they are facing. They are behind team-based care and are very collaborative.<br/><strong>– Maria Chambers, Senior Program Manager, Behavioral Health Network (BHN)</strong></p>
            </blockquote>
          </div>
        </div>
      </div>

      <div className={container}>
        <h2>How we approach our partnerships matter</h2><br/>
        
        <div className={thin +' '+ center}>
        <h3>Investing in Communities</h3>
        <blockquote className={white+' '+border}>
            <p>Rather than recreating, we wanted to tap into the organizations that already serve our communities.<br/><strong> – Kim Prendergast, Senior Director of Social Health, Community Care Cooperative</strong></p>
          </blockquote>
            <p>Critical to the success of our Flexible Service Program is the investment in partnerships. Rather than putting dollars into our own infrastructure to manage referrals and services internally, we are expanding the capacity of trusted community organizations who have been doing this work for decades. It is important to us to not simply refer our members to the non-profit sector for support but to invest new funds in ways that allow our partners to build upon their deep roots in serving communities and addressing health-related social needs.</p>
        </div>
      </div>

      <div className={grey}>
        <div className={container}>
            <h3 className={center+' '+thin}>“Buying” capacity rather than “building” it – benefits members in three primary ways.</h3>
        </div>
        <div className={container}>
          <ApproachCarousel></ApproachCarousel>
          <br/>
        </div>
        
        <div className={container}><br/>
          <p className={thin+' '+center+' '+larger}>Members benefit because they can engage directly with SSOs who now have expanded capacity <em>and</em> who are given the support and space to focus on their area of expertise.</p>
        </div>
      </div>

    </Layout>
  )
}

export default Approach